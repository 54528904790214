window.rh = function () {

    const $form_RH = $('#js-hr-form');
    const validator = $form_RH.validate({
        rules: {
            name: "required",
            lastname: "required",
            mobile: "required",
            email: {
                required: true,
                email: true
            },
            message: "required",
            
        },
        messages: {
            name: trans('all.The name is a required field'),
            lastname: trans('all.The lastname is a required field'),
            mobile: trans('all.The Moblie is a required field'),
            message: trans('all.The Message is a required field'),
            email: {
                required: trans('all.The email is a required field'),
                email: trans('all.We need your email address to contact you')
            },


        },
        submitHandler: function(form, e) {
            e.preventDefault();

            const $form = $(form);
            let formData = new FormData();
            let _token = $form.find('[name="_token"]').val();
            let name =$form.find('[name="name"]').val();
            let lastname =$form.find('[name="lastname"]').val();
            let mobile =$form.find('[name="mobile"]').val();
            let message =$form.find('[name="message"]').val();
            let email =$form.find('[name="email"]').val();
            let category = $form.find('[name="tipo"]').val();
            let files = $("#files")[0].files[0];
            formData.append('_token',_token );
            formData.append('name',name );
            formData.append('lastname',lastname );
            formData.append('mobile', mobile);
            formData.append('message',message );
            formData.append('email',email );
            formData.append('category',category );
            formData.append('file',files  );
            $.ajax({
                type: "POST",
                url: form.action,
                data: formData,
                processData: false,
                contentType: false,
                success: function (result) {
                    alert('envido com sucesso ')
                    //location.reload();


                }
            });
        },


    });


    $form_RH.on('keyup blur', function () { // fires on every keyup & blur
        if (validator.numberOfInvalids() <= 0) {                   // checks form for validity
          $form_RH.find('#sendrh').prop('disabled', false);        // enables button
      } else {
          $form_RH.find('#sendrh').prop('disabled', 'disabled');   // disables button
      }
    });

    $(".close", ".modal").click(function () {
        $(".modal").removeClass("active");
        $(".modal legend").html("");
        $(".modal .job-description").html("");
    });

    // $('#file').hide();


    $('item_menu').click(function () {
        var ajuda = $('.item_hover .menu-titulo .menu-titulo-limha', $(this));
        ajuda.removeClass("d-none");

    });

    $(".candidate-but").click(function () {
        let title = $(".rh-title", content).html();
        let descricao = $(".rh", content).html();
        let content = $(this).parent().parent()
        $(".modal").addClass("active");
        $(".modal legend").html(title);
        $(".modal .job-description").html(descricao);
        $("input[name='tipo']", ".js-hr-form").attr('value', $(this).data('tipo'));
    });
    $("#files").change(function () {
        $(".files_labe").text(this.files[0].name);
    });

}